import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import i2 from "./assets/images/BlackCard.png";
import i1 from "./assets/images/ClassicCard.png";
// import b1 from "./assets/images/Banner.png";
import h1 from "./assets/images/Header.png";

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: #ffffff;
  padding: 10px;
  font-weight: bold;
  color: #000000;
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;


export const ResponsiveWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: centre;
  align-items: stretched;
  width: 65%;
  flex-wrap: wrap;
  @media (min-width: 767px) {
    flex-direction: row;
  }
  @media (min-width: 2000px) {
    flex-direction: column;
  }
  @media (min-device-width: 320px) 
  and (max-device-width: 568px) {
    width: 100%;
  }
`;

export const StyledImg = styled.img`
  width: 420px;
  height: 275px;
  @media (min-width: 767px) {
    width: 420px;
    height: 275px;
  }

  @media (min-device-width: 320px) 
  and (max-device-width: 568px) {
    width: 200px;
    height: 135px;
    align: centre;
  }

  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledBackground = styled.img`
  position: absolute;
  z-index: -1;
  @media (min-width: 767px) {
    width: 710px;
    height: 510px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledHeader = styled.img`
  width: 520px;
  height: 190px;
  margin-bottom: 0px;
  @media (min-width: 767px) {
    width: 535px;
    height: 190px;
    margin-bottom: 30px;
  }
  @media (min-device-width: 320px) 
  and (max-device-width: 568px) {
    width: 320px;
    height: 115px;
    align: centre;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [feedback, setFeedback] = useState("Please note, prices exclude gas fees.");
  const [claimingNft, setClaimingNft] = useState(false);

  const [numBlackToMint, updateBlackMintNum] = useState(1); //Black
  const [numClassicToMint, updateClassicMintNum] = useState(1); //Classic

  const [selectedPass, setSelectedPass] = useState(0);

  const costPerType = [0.1, 0.02] //0.02 for Classic, 0.1 for Black

  const claimNFTs = (_amount, _passType) => {
    if (_amount <= 0) {
      return;
    }

    if(_passType != 0 && _passType != 1) {
      return;
    }

    setFeedback("Minting your NFT..."); 
    setClaimingNft(true);
    let gasLimitVal = 240000 * _amount;

    let passCost = costPerType[_passType];

    blockchain.smartContract.methods
      .mint(blockchain.account, _passType, _amount)
      .send({
        gasLimit: gasLimitVal.toString(),
        to: "0x7aa06a386017f5026e16e06ecc26a88dea797841",
        from: blockchain.account,
        value: blockchain.web3.utils.toWei((passCost * _amount).toString(), "ether"),
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        setFeedback(
          "Congratulations, you now own a MoonPaw VIP Pass! Go visit Opensea.io to view it."
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <s.Screen style={{ backgroundColor: "var(--black)" }}>
      <s.Container flex={1} ai={"center"} style={{ padding: 4 }}>
      <StyledHeader src={h1} />



        <ResponsiveWrapper flex={1} style={{ padding: 14 }}>
          {/* <s.SpacerMedium /> */}
          <s.Container
            flex={1}
            jc={"center"}
            ai={"center"}
            z-index="1"
          >
          {/* <StyledBackground src={b1} /> */}
            

            
            {Number(data.totalSupply) == 1000 ? (
              <>
                <s.TextTitle style={{ textAlign: "center" }}>
                  The sale has ended.
                </s.TextTitle>
                <s.SpacerSmall />
                <s.TextDescription style={{ textAlign: "center" }}>
                  You can still find MoonPaw VIP Passes NFTs on{" "}
                  <a
                    target={"_blank"}
                    href={"https://opensea.io/"}
                  >
                    Opensea.io
                  </a>
                </s.TextDescription>
              </>
            ) : (
              <>
                <s.TextTitle style={{ textAlign: "center", fontSize: 25, fontWeight: "bold"}}>
                Choose a pass to mint
                </s.TextTitle>
                <s.TextDescription style={{ textAlign: "center" }}>
                  {feedback}
                </s.TextDescription>
                <s.SpacerMedium />
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
                    <s.SpacerSmall />
                    <StyledButton
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      CONNECT
                    </StyledButton>
                    <s.SpacerMedium />
                    <s.TextDescription style={{ textAlign: "center", fontWeight: "bold" }}>
                      Connect to the Mainnet
                    </s.TextDescription>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription style={{ textAlign: "center" }}>
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (

                <s.Container ai={"center"} jc={"center"} fd={"column"} style={{ width: "70%"} }>

                  <s.TextTitle style={{fontSize: 20}}>
                  Black Card: 0.1 ETH
                  </s.TextTitle>

                  <s.Container ai={"center"} jc={"center"} fd={"row"} style={{ width: "70%", "margin-bottom": "20px"}}>
                    <input id="typeinp" type="range" min="1" max="10" class="slider" defaultValue="1" step="1" onChange={(e) => {
                        e.preventDefault();
                        updateBlackMintNum(e.target.value);
                    }}/>
                    <s.SpacerMedium />
                    <br/>
                    <StyledButton
                      disabled={claimingNft ? 1 : 0}
                      onClick={(e) => {
                        e.preventDefault();
                        claimNFTs(numBlackToMint, 0);
                        getData();
                      }}
                    >
                    {claimingNft ? "BUSY" : "BUY " + numBlackToMint}
                    </StyledButton>
                    <br></br>
                    </s.Container>


                    <s.TextTitle style={{ textAlign: "center", fontSize: 20}}>
                    Classic Card: 0.02 ETH
                    </s.TextTitle>

                    <s.Container ai={"center"} jc={"center"} fd={"row"} style={{ width: "70%" }}>
                    <input id="typeinp2" type="range" min="1" max="10" class="slider" defaultValue="1" step="1" onChange={(e) => {
                        e.preventDefault();
                        updateClassicMintNum(e.target.value);
                    }}/>
                    <s.SpacerMedium />
                    <br/>
                    <StyledButton
                      disabled={claimingNft ? 1 : 0}
                      onClick={(e) => {
                        e.preventDefault();
                        claimNFTs(numClassicToMint, 1);
                        getData();
                      }}
                    >
                    {claimingNft ? "BUSY" : "BUY " + numClassicToMint}
                    </StyledButton>
                  </s.Container>

                </s.Container>
                ) 
                
                
                }
              </>

              
            )}
          </s.Container>

        </ResponsiveWrapper>



        <ResponsiveWrapper flex={1} style={{ padding: 24 }}>
        <s.Container flex={1} jc={"center"} ai={"center"}>
            {/* <StyledImg alt={"example"} src={i2} /> */}
            <iframe id="4526a0b0-2fee-4a09-87fa-ca46c7927997" src="https://app.vectary.com/viewer/v1/?model=4526a0b0-2fee-4a09-87fa-ca46c7927997&env=kiara9dusk" frameborder="0" width="100%" height="280"></iframe>
          </s.Container>

          <s.Container flex={1} jc={"center"} ai={"center"}>
            {/* <StyledImg alt={"example"} src={i1} /> */}
            <iframe id="c4f3f662-93b3-47ae-917b-318fd231a260" src="https://app.vectary.com/viewer/v1/?model=c4f3f662-93b3-47ae-917b-318fd231a260&env=kiara9dusk" frameborder="0" width="100%" height="280"></iframe>
          </s.Container>
        </ResponsiveWrapper>





        <s.Container jc={"center"} ai={"center"} style={{ width: "90%" }}>
        <s.TextDescription style={{ textAlign: "center", fontSize: 15, fontWeight: "bold" } }>
                  Learn about the benefits of joining the MoonPaw VIP Program in this{" "}
                  <a
                    target={"_blank"}
                    href={"https://medium.com/@moonpaw/moonpaw-vip-pass-program-3b91dc3ac5f9"}
                  >
                    FAQ
                  </a>
        </s.TextDescription>
        </s.Container>

        <s.SpacerSmall />
        <s.Container jc={"center"} ai={"center"} style={{ width: "90%" }}>
           <s.TextDescription style={{ textAlign: "center", fontSize: 9 }}>
            Please note cannot issue refunds once a VIP Pass has been purchased.
           </s.TextDescription>
          <s.TextDescription style={{ textAlign: "center", fontSize: 9 }}>
            If you are having trouble minting, make sure you are connected to Mainnet. 
          </s.TextDescription>
          <s.TextDescription style={{ textAlign: "center", fontSize: 9 }}>
            We have set the gas limit to 240000 to ensure successful minting, we recommend that you do not change this.
          </s.TextDescription>
        </s.Container>
      </s.Container>
    </s.Screen>
  );
}

export default App;
